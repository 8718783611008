import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router'; 
import Langue from '../../../assets/langue.json';
declare var AOS: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css', '../auth/Login/login.component.css'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  carouselImages: string[] = [
    '../../../assets/bgg.jpg',
    '../../../assets/air.png',
    '../../../assets/air2.png',
    '../../../assets/port.png',
    '../../../assets/team2.jpg',
    '../../../assets/team3.jpg',
    '../../../assets/team7.jpg',
  ];
  currentIndex: number = 0;
  interval: any;
  display_nav_link: string = 'no';
  langue: any;
  direction: any;
  langue_list: any[] = Langue;
  langueh: any;
  modalVisible: boolean = false;
  modalVisible2: boolean = false;
  modalTitle: string = ''; 
  modalText: string = '';
  modalimage: string = '';
  modalTitle2: string = ''; 
  modalText2: string = '';
  modalimage2: string = '';
  items: string[] = [];


  services = [
    {
      id: '01',
      title: 'Air Freight',
      description: 'Our aim is to optimize and improve your supply chain...',
      iconSrc: '../../../assets/service-icon-1.png',
      altText: 'Truck',
      link: '#',
    },
    {
      id: '02',
      title: 'Road Freight',
      description: 'Our aim is to optimize and improve your supply chain...',
      iconSrc: '../../../assets/service-icon-2.png',
      altText: 'Ship',
      link: '#',
    },
    {
      id: '03',
      title: 'Road Freight',
      description: 'Our aim is to optimize and improve your supply chain...',
      iconSrc: '../../../assets/service-icon-3.png',
      altText: 'Ship',
      link: '#',
    },
  ];

  tradeItems = [
    {
      title: 'Letter of Credit',
      description: 'A letter of credit, is a document from a bank or other financial ...',
      image: '../../../assets/lettreofcredit.jpg',
    },
    {
      title: 'Certificate of origin',
      description: 'A certificate of origin (CO) is a document declaring ...',
      image: '../../../assets/cof.jpg',
    },
    {
      title: 'Shipping Guarantee',
      description: 'Shipping Guarantee refers to a written guarantee for picking up ...',
      image: '../../../assets/finance.jpg',
    },
    {
      title: 'ACID',
      description: 'New procedures have been implemented to better manage...',
      image: '../../../assets/export.jpg',
    },
  ];

  constructor(private router: Router) {  

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }


    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.langueh = this.langue_list[0]['AR'].header;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.langueh = this.langue_list[0]['EN'].header;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.langueh = this.langue_list[0]['FR'].header;
    }
  }

  ngOnInit(): void {
    this.startCarousel();
    AOS.init();  
  }

  navigate(route: string) {
    this.router.navigateByUrl(route); 
  }

  startCarousel() {
    this.interval = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.carouselImages.length;
    }, 3000); 
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);  
    }
  }

  prevImage() {
    this.currentIndex = (this.currentIndex - 1 + this.carouselImages.length) % this.carouselImages.length;
    clearInterval(this.interval);  
    this.startCarousel();
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.carouselImages.length;
    clearInterval(this.interval); 
    this.startCarousel();
  }

  goToImage(index: number) {
    this.currentIndex = index;
    clearInterval(this.interval);  
    this.startCarousel();
  }

  openModal(title: string) {
    if(title=='E-Logistic'){
    this.modalTitle = title;
    this.modalText = "PTS allows you as a shipping company to submit and track via a fast, reliable and secure electronic platform to the port authority, shipping company or terminal operator all your vessel berth management documents.";
    this.modalimage= '../../../assets/logistic.webp';
    this.items=[
      "Submit a letter of credit application to your bank's agency.",
      "Ship Agency Guarantee Request",
      "Request for certificate of origin"
    ];
    }else if(title=='E-Trade'){
      this.modalTitle = title;
      this.modalText = "PTS includes e-commerce services that facilitate all procedures related to foreign trade.";
      this.modalimage= '../../../assets/import-export.jpeg';
      this.items=  [
        "Ship Arrival Notice",
        "Dock Request",
        "Cargo Manifest",
        "Ship Departure Notice"
      ];
    }else{
      this.modalTitle = 'E-Costum';
      this.modalText = "PTS allows you as an importer, exporter or customs broker to submit and track all your declarations and management via a fast, reliable and secure electronic platform to the Customs Authority and the Food and Drug Control Agency.";
      this.modalimage= '../../../assets/police.png';
      this.items= [
        "Customs declaration of goods",
        "Technical Control Declaration",
      ];
    }
    this.modalVisible = true; 
  }



  
  openModal2(title: string) {
    if(title=='Letter of Credit'){
    this.modalTitle2 = title;
    this.modalText2 = "A letter of credit, also known as a credit letter, is a document from a bank or other financial institution guaranteeing that a specific payment will be made in a business transaction.";
    this.modalimage2= '../../../assets/logistic.webp';
    }else if(title=='Certificate of origin'){
      this.modalTitle2 = title;
      this.modalText2 = " A certificate of origin (CO) is a document declaring in which country a commodity or good was manufactured. The certificate of origin contains information regarding the product, its destination, and the country of export. For example, a good may be marked Made in the USA or Made in China.Required by many treaty agreements for cross-border trade, the CO is an important form because it can help determine whether certain goods are eligible for import, or whether goods are subject to duties.";
      this.modalimage2= '../../../assets/logistic.webp';
    }else if(title=='Shipping Guarantee'){
      this.modalTitle2 = title;
      this.modalText2 = "Shipping Guarantee refers to a written guarantee for picking up the goods in advance, which is issued by the bank to the ship company upon the request of the customer.";
      this.modalimage2= '../../../assets/logistic.webp';
    }else{
      this.modalTitle2 = title;
      this.modalText2 = "New procedures have been implemented to better manage risks associated with the import of goods, aiming to enhance the dematerialization of customs procedures and border controls.This system is based on the Advance Cargo Information (ACID) mechanism, which represents a significant step forward in securing imports. It complements the services provided by the Single Window by reinforcing the traceability and transparency of operations.";
      this.modalimage2= '../../../assets/logistic.webp';
    }
    this.modalVisible2 = true; 
  }

  closeModal() {
    this.modalVisible = false;
    this.modalVisible2 = false;
  }
  getBackgroundUrl(imagePath: string): string {
    return `url('${imagePath}') center/cover`;  
  }
}
