<div class="footer" id="footer">
    <div class="logo">
        <img src="../../../../assets/logo3.png"/>
    </div>
    <p class="text" id="text">Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward</p>
    <div class="navigation">
        <div class="item-nav" id="item-nav">Home</div>
        <div class="item-nav" id="item-nav">E-Logistic</div>
        <div class="item-nav" id="item-nav">E-Trade</div>
        <div class="item-nav" id="item-nav">E-Costum</div>
    </div>
    <div class="contact" id="contact">
        <div class="info-left" id="info-left">Call Us: +(218) 0213607085   |    info@ltnet.ly</div>
        <div class="info-cneter"> <img src="../../../assets/icons/fb.png"/>  <img src="../../../assets/icons/twitter.png"/>
             <img src="../../../assets/icons/youtube.png"/> <img src="../../../assets/icons/linkedin.png"/> </div>
        <div class="info-right"><button>Se connecter</button></div>
    </div>
    <div class="Copyright">Our Privacy Policy. Copyright © MSS Pte. Ltd. All rights reserved.</div>
</div>