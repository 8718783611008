export const environment = {
  production: true,
  Url: 'http://51.68.84.74:8011/',
  UrlSession: 'http://51.68.84.74:9095/',//135
  UrlCms: 'https://www.tunisia-export.tn/',
  UrlPort: 'http://51.68.84.74:9991/',
  urlGestionUser: 'http://51.68.84.74:5001/',
  urlEservice: 'http://51.68.84.74:4201/',
  urllogin: 'http://51.68.84.74:9095/',//135
  UrlBil: 'http://51.68.84.74:8011/',
  //UrlGetway: 'http://51.68.84.74:8011/',
  UrlGetway: 'http://51.68.84.74:8011/',
  UrlFde: 'http://51.68.84.74:8011/fde/api/',
  UrlFde1: 'http://51.68.84.74:8011/fde/api/',
  //UrlFde : 'http://51.68.84.74:9105/api/',
  mainUrlAttacemnts: 'http://51.68.84.74/attachments/',
  urlAgence: 'http://51.68.84.74:9696/api/gestion_code/agences',
  urlBanque: 'http://51.68.84.74:9696/api/gestion_code/banques',
  urlCaution: 'http://51.68.84.74:9024/api/',
  UrlGestionUser: 'http://51.68.84.74:9095/',//135
  UrlAgrement: 'http://51.68.84.74:2024/api/agrements',
  //UrlAgrementMessageUser: 'http://51.68.84.74:2024/api/',

  baseURL: 'http://51.68.84.74:9197/api/',

};
