<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" >
   
    <div class="carousel-inner" style="margin-top: 50px;">
      <div class="carousel-item active">
        <div class="group">
            <img src="../../../assets/BTL.png" class="d-block w-33" style="height: 100px;" alt="...">
            <img src="../../../assets/partenaire 3.png" class="d-block w-33" style="height: 100px;" alt="...">
            <img src="../../../assets/partenaire 2.png" class="d-block w-33" style="height: 100px;" alt="...">
        </div>
    </div>
      <div class="carousel-item" >
        <div class="group">
            <img src="../../../assets/background.jpg" class="d-block w-33" style="height: 200px;" alt="...">
            <img src="../../../assets/background.jpg" class="d-block w-33" style="height: 200px;" alt="...">
        </div>
      </div>
      <div class="carousel-item">
        <div class="group">
            <img src="../../../assets/background.jpg" class="d-block w-33" style="height: 200px;" alt="...">
            <img src="../../../assets/background.jpg" class="d-block w-33" style="height: 200px;" alt="...">
        </div>
      </div>
    </div>
    <a class="carousel-control-prev"  href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" style="background-color: rgb(0, 0, 0); border-radius: 5px;" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next"  href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" style="background-color: rgb(0, 0, 0); border-radius: 5px;;" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>