<app-navbar [item]="display_nav_link"></app-navbar>
<div class="containerbg">
  <div class="auth_container">
    <div class="left_form" style="padding: 10px">
      <h3 class="title" style="color: #0d6dfdda">{{ langueh.bienvenu }}</h3>
      <p style="text-align: justify; color: #ffff; font-size: 16px">
        Libya Trade Network (LTNET) is committed to facilitating your trade through an integrated platform that connects foreign trade stakeholders
        in Libya, such as trading companies, freight forwarders, customs brokers, shipping companies, insurers and banks, with related institutions 
        (customs, port authorities, etc.).
      </p>
      <p
        style="text-align: left; color: #ffff; font-size: 16px"
        class="resposive"
      >
      We offer digital solutions to automate and simplify import and export processes. With our one-stop service, your trade operations become fast, 
      secure and accessible online, reducing costs and paperwork.
      </p>
      <p
        style="text-align: left; color: #ffff; font-size: 16px"
        class="resposive"
      >
      As an e-commerce-based network, LTNET is committed to improving Libya's competitiveness by offering modern tools for logistics, regulatory compliance 
      and trade support services.
      </p>
      <input
        class="button"
        style="width: 180px"
        type="button"
        value="{{ langueh.commencer }}"
        (click)="navigate('Auth/login')"
      />
    </div>
    <div class="right_img" style="background: none">
      <!-- Indicateurs -->
      <div class="carousel-indicators">
        <span
          class="indicator"
          *ngFor="let image of carouselImages; let i = index"
          [class.active]="i === currentIndex"
          (click)="goToImage(i)"
        ></span>
      </div>
      <!-- Carrousel -->
      <div class="main-carousel">
        <div
          class="main-carousel-item"
          *ngFor="let image of carouselImages; let i = index"
          [style.backgroundImage]="'url(' + image + ')'"
          [style.transform]="'translateX(' + -currentIndex * 100 + '%)'"
        ></div>
      </div>
      <!-- Flèches de navigation -->
      <div class="carousel-navigation">
        <button class="carousel-button prev" (click)="prevImage()">
          <img src="../../../assets/icons/next.png" width="25px" />
        </button>
        <button class="carousel-button next" (click)="nextImage()">
          <img src="../../../assets/icons/next.png" width="25px" />
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Partenaires -->
<div class="partenaires">
  <div class="img" >{{ langueh.partners }}</div>
  <div style="width: 100%; height: 200px">
    <app-alliances-strategiques></app-alliances-strategiques>
  </div>
</div>
<!-- why you need Us -->
<div class="needus-container">
  
  <div class="needus-image">
    
    <div class="experience">
      <p></p>
      <span></span>
    </div>
  </div>
  <div class="needus-content">
    <h2 class="h2 section-title">
      <img
        class="profil"
        style="width: 100px; margin-right: 10px,"
        src="../../../assets/CEO2.jpg"
      />
      What is our report?
    </h2>
    <p class="quote">
      "Welcome to the Libya Trade."
    </p>
    <p class="def">
      The Libya Trade aims to strengthen the competitiveness of Libyan companies by offering e-commerce services through an 
      integrated platform, the single window in Libya. This network simplifies and dematerializes the customs clearance process for imports and 
      exports, thus allowing faster and paperless management of administrative procedures.It offers Libyan companies a modern and efficient solution, in line with the best global practices in e-commerce.
    </p>
    <p class="def">
      On this homepage, you will find several useful menus: "Presentation of the Libya Trade Network", "Information Center", "Customer Center", 
      "E-Commerce", "e-Logistics", "e-Customs" and "Technical Control". These menus are designed to provide you with online services that 
      facilitate commercial, logistics and customs procedures in the context of international trade. This platform is an essential tool in a world
      where e-commerce is becoming a pillar of global economic growth.
    </p>
    <p class="def">
      Thank you for your interest in Libya Trade Network’s e-commerce services. Your participation is valuable in supporting the development of 
      smoother, faster and more transparent trade in Libya. Thank you for being part of this initiative to connect Libya to international trade 
      opportunities.
    </p>

    <p class="author"><span> Mohammed Darwich -</span> Libya Trade Network LTN</p>
  </div>
</div>

<!-- Our Areas of Intervention-->
<div class="oup-container">
  <div data-aos="fade-up"  data-aos-duration="800" data-aos-delay="200">
  <p class="section-subtitle">All Services</p>
  <h2 class="h2 section-title" style="color: #007bff; font-weight: 700;font-size: 36px">Across our areas of intervention</h2>
  <p class="section-text">
    Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since
    the when an
    printer took.
  </p>
</div>
  <div class="oup-container-grid" data-aos="fade-up"  data-aos-duration="1200" data-aos-delay="200">
    <div class="grid-item grid-item" >
      <div class="head-item">
        <div class="icon-oup">
          <img src="../../../assets/icons/fleches.png" />
        </div>
        <h4 class="section-title">E-Logistic</h4>
      </div>
      <p class="centered-text">
        PTS allows you as a shipping company to submit and track...
      </p>
      <div class="btn-div-right">
        <button (click)="openModal('E-Logistic')">
          Read more 
          <img src="../../../assets/icons/show-more.png" width="15px;" />
        </button>
        <hr>
      </div>
    </div>
    <div class="grid-item grid-item2" >
      <div class="head-item">
        <div class="icon-oup">
          <img src="../../../assets/icons/trade.png" />
        </div>
        <h4 class="section-title">E-Trade</h4>
      </div>
      <p class="centered-text">
        PTS includes e-commerce services that facilitate ...
      </p>
      <div class="btn-div-right">
        <button (click)="openModal('E-Trade')">
          Read more
          <img src="../../../assets/icons/show-more.png" width="15px;" />
        </button>
      </div>
    </div>
    <div class="grid-item grid-item4" >
      <div class="head-item">
        <div class="icon-oup">
          <img src="../../../assets/icons/transport-maritime.png" />
        </div>
        <h4 class="section-title">E-Costum</h4>
      </div>
      <p class="centered-text">
        PTS allows you as an importer, exporter or customs broker...
      </p>
      <!-- suite de paragraphe de  E-costum 
       <p>de contrôle aux frontières seront exposés dans ce bouquet de services pour vous éviter les déplacements multiples et recevoir les autorisations par voie électronique </p>
       -->
      <div class="btn-div-right">
        <button (click)="openModal('E-costum')">
          Read more
          <img src="../../../assets/icons/show-more.png" width="15px" />
        </button>
      </div>
    </div>
  </div>
  <!-- Modal -->
<div id="myModal" class="modal" [ngStyle]="{display: modalVisible ? 'block' : 'none'}">
  <div class="modal-content">
    <div class="modal-header">
      <h4></h4>
      <h4>{{ modalTitle }}</h4>
      <span class="close" (click)="closeModal()">&times;</span>
    </div>
    <div class="modal-body">
      <img src="{{ modalimage }}" alt="">
      <p>{{ modalText }}</p>
      <div class="block-modal">
        <div class="part1">
          <span *ngFor="let item of items">
            <i class="fas fa-check-circle icon-red" style="color: red;"></i>&ensp;{{ item }}
          </span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="closeModal()">Close&ensp;<i class="fas fa-times"></i></button>
    </div>
  </div>
</div>

</div>

<!-- Our Areas of Intervention  -->







    

<div class="logistic"  id="e-logistic">
  <!--<div class="red"></div>-->
  <div class="partie1" data-aos="fade-right" data-aos-duration="500" data-aos-delay="200">
    <img src="../../../assets/LOGISTIC.png" alt="logistic">
  </div>
  <div class="partie2" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">

    <div class="titre">
      <div class="blue"></div>
      <span>Why do you choose us</span>
    </div>
    <h2 class="h2 section-title">Our comprehensive and innovative E-logistics service tailored to your management needs.</h2>
    <div class="cardi">
      <div class="cercle">
        <img src="../../../assets/icon_arrival.png" alt="icon-cercle1">
      </div>
      <div class="text_cardi">
        <h3 class="section-title">Shipping arrival</h3>
        <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad voluptas, rem debitis placeat similique eos magnam alias hic saepe possimus unde dolorem eveniet.</p>
      </div>
    </div>
    <div class="cardi">
      <div class="cercle">
        <img src="../../../assets/ancre.png" alt="icon-cercle1">
      </div>
      <div class="text_cardi">
        <h3 class="section-title">Ship Docking</h3>
        <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad voluptas, rem debitis placeat similique eos magnam alias hic saepe possimus unde dolorem eveniet.</p>
      </div>
    </div>
    <div class="cardi">
      <div class="cercle">
        <img src="../../../assets/icon_departure.png" alt="icon-cercle1">
      </div>
      <div class="text_cardi">
        <h3 class="section-title">Shipping Departure</h3>
        <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad voluptas, rem debitis placeat similique eos magnam alias hic saepe possimus unde dolorem eveniet.</p>
      </div>
    </div>
    <div class="cardi">
      <div class="cercle">
        <img src="../../../assets/icon_cargo.png" alt="icon-cercle1">
      </div>
      <div class="text_cardi">
        <h3 class="section-title">Cargo Manifest</h3>
        <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad voluptas, rem debitis placeat similique eos magnam alias hic saepe possimus unde dolorem eveniet.</p>
      </div>
    </div>
    
  </div>
</div>










    <!--*********** E-trade section ***********-->

    <div class="e-trade-sec" id="e-trade">
      <div class="header" data-aos="fade-up"  data-aos-duration="800" data-aos-delay="200">
      <p class="section-subtitle">All Services</p>
      <h2 class="h2 section-title" >
        Of online E-Trade Solutions
      </h2>
      <p class="section-text" data-aos="fade-up"  data-aos-duration="800" data-aos-delay="200">
        The online services presented under this section currently concern the formalities required to be completed
        with your Bank to initiate an import of goods by opening a letter of credit or to obtain a bank guarantee to have your goods 
        delivered to your carrier with your Chamber of Commerce to obtain your certificate of origin in order to give it to your customer.          
      </p>
    </div>
      
      <div class="block" data-aos="fade-up" data-aos-duration="1200"  data-aos-delay="200">
        <div class="custom-card">
          <div class="e-trade-container-flex" >
            <div class="trade-item-flex" *ngFor="let item of tradeItems" >
              <div class="img-bg" [ngStyle]="{'background': 'url(' + item.image + ')', 'background-position': 'center', 'background-size': 'cover'}"></div>
              <div class="description-content">
                <h4 class="card-title">{{ item.title }}</h4>
                <p class="card-text">{{ item.description }}</p>
                <div class="footer">
                  <button class="read-more" (click)="openModal2(item.title)">

                    Read More&ensp;<i class="fas fa-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- Modal -->
<div id="myModal" class="modal" [ngStyle]="{display: modalVisible2 ? 'block' : 'none'}">
  <div class="modal-content">
    <div class="modal-header">
      <h4></h4>
      <h4>{{ modalTitle2 }}</h4>
      <span class="close" (click)="closeModal()">&times;</span>
    </div>
    <div class="modal-body">
      <p>{{ modalText2 }}</p>
    </div>
    <div class="modal-footer">
      <button (click)="closeModal()">Close&ensp;<i class="fas fa-times"></i></button>
    </div>
  </div>
</div>




  <!--**************************************-->  
    

    <app-footer></app-footer>
    <app-level-up></app-level-up>

