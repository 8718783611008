import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PagerService } from 'src/Services/pagination/pager.service';
import { AffectationModule } from './Modules/Affectation-Document/Affectation.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { TranslationDirective } from './Translate/translation.directive';
import { AuthModule } from './Modules/auth/auth.module';
import { LandingPageModule } from './Modules/landing-page/landing-page.module';
import { NavbarModule } from './Modules/navbar/navbar.module';
import { ToastrModule } from 'ngx-toastr';
//import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [AppComponent,TranslationDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AffectationModule,
    NavbarModule,
    LandingPageModule,
    AuthModule,
    ToastrModule.forRoot({
      // Toast options
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    

  ],
  providers: [PagerService,DatePipe, TranslationDirective,],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
